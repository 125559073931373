const Paypal = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="29"
      viewBox="0 0 44 29"
      fill="none"
      {...props}
    >
      <path
        d="M40.5266 2.44775C41.5242 2.44775 42.3424 3.266 42.3424 4.26354V25.1056C42.3424 26.1032 41.5242 26.9214 40.5266 26.9214H3.47399C2.47645 26.9214 1.6582 26.1032 1.6582 25.1056V4.26354C1.6582 3.266 2.47645 2.44775 3.47399 2.44775H40.5266Z"
        stroke="#E9E9E9"
      />
      <path
        d="M27.6741 10.3997C27.9057 9.24177 27.6741 8.43124 26.9794 7.73651C26.2846 6.92598 25.0109 6.57861 23.3899 6.57861H18.6425C18.2951 6.57861 18.0636 6.81019 17.9478 7.15756L16.2109 18.8523C16.2109 19.0839 16.3267 19.3155 16.5583 19.3155H19.6846L20.1478 15.3786L22.232 12.8312L27.6741 10.3997Z"
        fill="#003087"
      />
      <path
        d="M27.6733 10.3999L27.4418 10.6315C26.8628 13.8736 24.8944 15.0315 22.1154 15.0315H20.8418C20.4944 15.0315 20.2628 15.2631 20.147 15.6104L19.4523 20.1262L19.2207 21.2841C19.2207 21.5157 19.3365 21.7473 19.5681 21.7473H21.9997C22.347 21.7473 22.5786 21.5157 22.5786 21.2841V21.1683L23.0418 18.3894V18.2736C23.0418 18.042 23.3891 17.8104 23.6207 17.8104H23.9681C26.3997 17.8104 28.2523 16.8841 28.7154 14.1052C28.947 12.9473 28.8312 12.021 28.2523 11.3262C28.1365 10.7473 27.9049 10.5157 27.6733 10.3999Z"
        fill="#3086C8"
      />
      <path
        d="M26.9781 10.1682C26.8623 10.0524 26.7465 10.0524 26.6307 10.0524C26.5149 10.0524 26.3991 10.0524 26.2833 9.93659C25.936 9.8208 25.4728 9.8208 25.0096 9.8208H21.536C21.4202 9.8208 21.3044 9.8208 21.3044 9.93659C21.0728 10.0524 20.957 10.1682 20.957 10.3997L20.1465 15.4945V15.6103C20.1465 15.2629 20.4939 15.0313 20.8412 15.0313H22.3465C25.2412 15.0313 27.0939 13.8734 27.6728 10.6313V10.3997C27.557 10.284 27.3254 10.1682 27.0939 10.1682H26.9781Z"
        fill="#012169"
      />
    </svg>
  )
}

export default Paypal
